import { contentLoaded } from '../../globals';

export function supportsWbrElement(): boolean {
	const testDiv = document.createElement('div');
	testDiv.style.cssText = `position:fixed;width:1px;line-height:16px;word-wrap:normal;word-break:normal;white-space:normal;border: 1px solid red;top:-1000px`;
	testDiv.innerHTML = `x<wbr>x`;
	document.body.appendChild(testDiv);
	const supportsWbr = testDiv.clientHeight > 16;
	document.body.removeChild(testDiv);
	return supportsWbr;
}

export function polyfillWbrElement() {
	const style = document.createElement('style');
	style.textContent = `wbr::after { content: "\u200B"}`;
	document.head.appendChild(style);
}

async function ensureWbr() {
	await contentLoaded;
	if (!supportsWbrElement()) {
		polyfillWbrElement();
	}
}

ensureWbr();
