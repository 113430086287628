export const themeMap = {
	"light": {
		"text": "hsla(0, 0%, 9%, 1)",
		"text-subtle": "hsla(0, 0%, 37%, 1)",
		"text-invert": "hsla(0, 0%, 100%, 1)",
		"text-glow-high-contrast": "hsla(0, 0%, 9%, 0.999)",
		"box-shadow-light": "hsla(0, 0%, 0%, 0.108)",
		"box-shadow-medium": "hsla(0, 0%, 0%, 0.132)",
		"box-shadow-heavy": "hsla(0, 0%, 0%, 0.18)",
		"box-shadow-extra-heavy": "hsla(0, 0%, 0%, 0.22)",
		"overlay": "hsla(0, 0%, 0%, 0.7)",
		"overlay-invert": "hsla(0, 0%, 100%, 0.999)",
		"body-background": "hsla(0, 0%, 100%, 0.998)",
		"body-background-medium": "hsla(0, 0%, 98%, 1)",
		"body-background-dark": "hsla(0, 0%, 95%, 1)",
		"border": "hsla(0, 0%, 89%, 1)",
		"hover-base": "hsla(0, 0%, 18%, 1)",
		"hover-invert": "hsla(0, 0%, 98%, 0.999)",
		"border-white-high-contrast": "hsla(0, 0%, 100%, 0)",
		"border-yellow-high-contrast": "hsla(0, 0%, 0%, 0)",
		"code-highlight-background": "hsla(44, 100%, 90%, 1)",
		"visited": "hsla(280, 100%, 30%, 1)",
		"score-low-off": "hsla(16, 99%, 43%, 0.3)",
		"score-low": "hsla(16, 99%, 43%, 1)",
		"score-medium-off": "hsla(44, 100%, 50%, 0.3)",
		"score-medium": "hsla(44, 100%, 50%, 1)",
		"score-high-off": "hsla(120, 77%, 30%, 0.3)",
		"score-high": "hsla(120, 77%, 30%, 1)",
		"primary-base": "hsla(206, 100%, 35%, 1)",
		"primary-background": "hsla(205, 100%, 94%, 1)",
		"primary-background-glow-high-contrast": "hsla(205, 100%, 94%, 0.999)",
		"primary-dark": "hsla(206, 100%, 15%, 1)",
		"primary-hover": "hsla(206, 100%, 28%, 1)",
		"primary-active": "hsla(206, 100%, 20%, 1)",
		"primary-box-shadow": "hsla(206, 100%, 35%, 0.3)",
		"primary-invert": "hsla(0, 0%, 100%, 0.997)",
		"secondary-base": "hsla(0, 0%, 67%, 1)",
		"secondary-background": "hsla(0, 0%, 89%, 0.999)",
		"secondary-background-glow-high-contrast": "hsla(0, 0%, 89%, 0.998)",
		"secondary-dark": "hsla(0, 0%, 18%, 0.999)",
		"secondary-hover": "hsla(0, 0%, 78%, 1)",
		"secondary-active": "hsla(0, 0%, 78%, 0.999)",
		"secondary-box-shadow": "hsla(0, 0%, 0%, 0.3)",
		"secondary-invert": "hsla(0, 0%, 0%, 1)",
		"tertiary-base": "hsla(0, 0%, 27%, 1)",
		"tertiary-background": "hsla(0, 0%, 9%, 0.998)",
		"tertiary-background-glow-high-contrast": "hsla(0, 0%, 9%, 0.997)",
		"tertiary-dark": "hsla(0, 0%, 89%, 0.997)",
		"tertiary-hover": "hsla(0, 0%, 37%, 0.999)",
		"tertiary-active": "hsla(0, 0%, 46%, 1)",
		"tertiary-box-shadow": "hsla(206, 100%, 35%, 0.299)",
		"tertiary-invert": "hsla(0, 0%, 100%, 0.996)",
		"success-base": "hsla(120, 77%, 30%, 0.999)",
		"success-background": "hsla(120, 77%, 90%, 1)",
		"success-background-glow-high-contrast": "hsla(120, 77%, 90%, 0.999)",
		"success-dark": "hsla(120, 77%, 15%, 1)",
		"success-hover": "hsla(120, 77%, 25%, 1)",
		"success-active": "hsla(120, 77%, 20%, 1)",
		"success-box-shadow": "hsla(120, 77%, 30%, 0.299)",
		"success-invert": "hsla(0, 0%, 100%, 0.995)",
		"info-base": "hsla(262, 46%, 58%, 1)",
		"info-background": "hsla(262, 46%, 90%, 1)",
		"info-background-glow-high-contrast": "hsla(262, 46%, 90%, 0.999)",
		"info-dark": "hsla(262, 46%, 25%, 1)",
		"info-hover": "hsla(262, 46%, 50%, 1)",
		"info-active": "hsla(262, 46%, 37%, 1)",
		"info-box-shadow": "hsla(262, 46%, 90%, 0.998)",
		"info-invert": "hsla(0, 0%, 100%, 0.994)",
		"warning-base": "hsla(44, 100%, 50%, 0.999)",
		"warning-background": "hsla(44, 100%, 90%, 0.999)",
		"warning-background-glow-high-contrast": "hsla(44, 100%, 90%, 0.998)",
		"warning-dark": "hsla(44, 100%, 20%, 1)",
		"warning-hover": "hsla(44, 100%, 40%, 1)",
		"warning-active": "hsla(44, 100%, 30%, 1)",
		"warning-box-shadow": "hsla(44, 100%, 50%, 0.299)",
		"warning-invert": "hsla(0, 0%, 0%, 0.999)",
		"danger-base": "hsla(16, 99%, 43%, 0.999)",
		"danger-background": "hsla(16, 99%, 90%, 1)",
		"danger-background-glow-high-contrast": "hsla(16, 99%, 90%, 0.999)",
		"danger-dark": "hsla(16, 99%, 20%, 1)",
		"danger-hover": "hsla(16, 99%, 35%, 1)",
		"danger-active": "hsla(16, 99%, 25%, 1)",
		"danger-box-shadow": "hsla(16, 99%, 43%, 0.299)",
		"danger-invert": "hsla(0, 0%, 100%, 0.993)"
	},
	"dark": {
		"text": "hsla(0, 0%, 89%, 1)",
		"text-subtle": "hsla(0, 0%, 78%, 1)",
		"text-invert": "hsla(0, 0%, 18%, 1)",
		"text-glow-high-contrast": "hsla(0, 0%, 89%, 0.999)",
		"box-shadow-light": "hsla(0, 0%, 100%, 0.108)",
		"box-shadow-medium": "hsla(0, 0%, 100%, 0.132)",
		"box-shadow-heavy": "hsla(0, 0%, 100%, 0.18)",
		"box-shadow-extra-heavy": "hsla(0, 0%, 100%, 0.22)",
		"overlay": "hsla(0, 0%, 0%, 0.7)",
		"overlay-invert": "hsla(0, 0%, 100%, 1)",
		"body-background": "hsla(0, 0%, 9%, 1)",
		"body-background-medium": "hsla(0, 0%, 18%, 0.999)",
		"body-background-dark": "hsla(0, 0%, 14%, 1)",
		"border": "hsla(0, 0%, 27%, 1)",
		"hover-base": "hsla(0, 0%, 78%, 0.999)",
		"hover-invert": "hsla(0, 0%, 18%, 0.998)",
		"border-white-high-contrast": "hsla(0, 0%, 100%, 0)",
		"border-yellow-high-contrast": "hsla(0, 0%, 0%, 0)",
		"code-highlight-background": "hsla(120, 77%, 20%, 1)",
		"visited": "hsla(246, 61%, 69%, 1)",
		"score-low-off": "hsla(16, 99%, 43%, 0.7)",
		"score-low": "hsla(16, 99%, 43%, 1)",
		"score-medium-off": "hsla(44, 100%, 50%, 0.3)",
		"score-medium": "hsla(44, 100%, 60%, 1)",
		"score-high-off": "hsla(120, 77%, 30%, 0.7)",
		"score-high": "hsla(120, 77%, 40%, 1)",
		"primary-base": "hsla(206, 100%, 65%, 1)",
		"primary-background": "hsla(206, 100%, 15%, 1)",
		"primary-background-glow-high-contrast": "hsla(206, 100%, 15%, 0.999)",
		"primary-dark": "hsla(206, 100%, 82%, 1)",
		"primary-hover": "hsla(206, 100%, 50%, 1)",
		"primary-active": "hsla(206, 100%, 35%, 1)",
		"primary-box-shadow": "hsla(206, 100%, 35%, 0.3)",
		"primary-invert": "hsla(0, 0%, 0%, 1)",
		"secondary-base": "hsla(0, 0%, 46%, 1)",
		"secondary-background": "hsla(0, 0%, 18%, 0.997)",
		"secondary-background-glow-high-contrast": "hsla(0, 0%, 18%, 0.996)",
		"secondary-dark": "hsla(0, 0%, 89%, 0.998)",
		"secondary-hover": "hsla(0, 0%, 57%, 1)",
		"secondary-active": "hsla(0, 0%, 67%, 1)",
		"secondary-box-shadow": "hsla(0, 0%, 0%, 0.699)",
		"secondary-invert": "hsla(0, 0%, 100%, 0.999)",
		"tertiary-base": "hsla(0, 0%, 27%, 0.999)",
		"tertiary-background": "hsla(0, 0%, 9%, 0.999)",
		"tertiary-background-glow-high-contrast": "hsla(0, 0%, 9%, 0.998)",
		"tertiary-dark": "hsla(0, 0%, 89%, 0.997)",
		"tertiary-hover": "hsla(0, 0%, 37%, 1)",
		"tertiary-active": "hsla(0, 0%, 46%, 0.999)",
		"tertiary-box-shadow": "hsla(206, 100%, 35%, 0.299)",
		"tertiary-invert": "hsla(0, 0%, 100%, 0.998)",
		"success-base": "hsla(120, 77%, 30%, 1)",
		"success-background": "hsla(120, 77%, 15%, 1)",
		"success-background-glow-high-contrast": "hsla(120, 77%, 15%, 0.999)",
		"success-dark": "hsla(120, 77%, 90%, 1)",
		"success-hover": "hsla(120, 77%, 40%, 0.999)",
		"success-active": "hsla(120, 77%, 60%, 1)",
		"success-box-shadow": "hsla(120, 77%, 30%, 0.699)",
		"success-invert": "hsla(0, 0%, 0%, 0.999)",
		"info-base": "hsla(262, 46%, 58%, 1)",
		"info-background": "hsla(262, 46%, 25%, 1)",
		"info-background-glow-high-contrast": "hsla(262, 46%, 25%, 0.999)",
		"info-dark": "hsla(262, 46%, 90%, 1)",
		"info-hover": "hsla(262, 46%, 65%, 1)",
		"info-active": "hsla(262, 46%, 70%, 1)",
		"info-box-shadow": "hsla(262, 46%, 17%, 0.7)",
		"info-invert": "hsla(0, 0%, 100%, 0.997)",
		"warning-base": "hsla(44, 100%, 50%, 1)",
		"warning-background": "hsla(44, 100%, 20%, 1)",
		"warning-background-glow-high-contrast": "hsla(44, 100%, 20%, 0.999)",
		"warning-dark": "hsla(44, 100%, 90%, 1)",
		"warning-hover": "hsla(44, 100%, 60%, 0.999)",
		"warning-active": "hsla(44, 100%, 70%, 1)",
		"warning-box-shadow": "hsla(44, 100%, 50%, 0.7)",
		"warning-invert": "hsla(0, 0%, 0%, 0.998)",
		"danger-base": "hsla(16, 99%, 43%, 0.999)",
		"danger-background": "hsla(16, 99%, 20%, 1)",
		"danger-background-glow-high-contrast": "hsla(16, 99%, 20%, 0.999)",
		"danger-dark": "hsla(16, 99%, 90%, 1)",
		"danger-hover": "hsla(16, 99%, 55%, 1)",
		"danger-active": "hsla(16, 99%, 70%, 1)",
		"danger-box-shadow": "hsla(16, 99%, 43%, 0.699)",
		"danger-invert": "hsla(0, 0%, 100%, 0.996)"
	},
	"high-contrast": {
		"text": "hsla(0, 0%, 100%, 1)",
		"text-subtle": "hsla(0, 0%, 100%, 0.999)",
		"text-invert": "hsla(0, 0%, 0%, 1)",
		"text-glow-high-contrast": "hsla(60, 100%, 50%, 1)",
		"box-shadow-light": "hsla(0, 0%, 100%, 0)",
		"box-shadow-medium": "hsla(0, 0%, 100%, 0.001)",
		"box-shadow-heavy": "hsla(0, 0%, 100%, 0.002)",
		"box-shadow-extra-heavy": "hsla(0, 0%, 100%, 0.003)",
		"overlay": "hsla(0, 0%, 27%, 1)",
		"overlay-invert": "hsla(0, 0%, 100%, 0.998)",
		"body-background": "hsla(0, 0%, 0%, 0.999)",
		"body-background-medium": "hsla(0, 0%, 0%, 0.998)",
		"body-background-dark": "hsla(0, 0%, 0%, 0.997)",
		"border": "hsla(0, 0%, 100%, 0.997)",
		"hover-base": "hsla(60, 100%, 50%, 0.999)",
		"hover-invert": "hsla(0, 0%, 0%, 0.996)",
		"border-white-high-contrast": "hsla(0, 0%, 100%, 0.996)",
		"border-yellow-high-contrast": "hsla(60, 100%, 50%, 0.998)",
		"code-highlight-background": "hsla(120, 77%, 20%, 1)",
		"visited": "hsla(106, 100%, 50%, 1)",
		"score-low-off": "hsla(16, 99%, 35%, 1)",
		"score-low": "hsla(16, 99%, 90%, 1)",
		"score-medium-off": "hsla(44, 100%, 50%, 0.7)",
		"score-medium": "hsla(44, 100%, 80%, 1)",
		"score-high-off": "hsla(120, 77%, 25%, 1)",
		"score-high": "hsla(120, 77%, 90%, 1)",
		"primary-base": "hsla(60, 100%, 50%, 0.997)",
		"primary-background": "hsla(0, 0%, 0%, 0.995)",
		"primary-background-glow-high-contrast": "hsla(60, 100%, 50%, 0.996)",
		"primary-dark": "hsla(60, 100%, 50%, 0.995)",
		"primary-hover": "hsla(60, 100%, 60%, 1)",
		"primary-active": "hsla(60, 100%, 60%, 0.999)",
		"primary-box-shadow": "hsla(0, 0%, 100%, 0.995)",
		"primary-invert": "hsla(0, 0%, 0%, 0.994)",
		"secondary-base": "hsla(0, 0%, 89%, 1)",
		"secondary-background": "hsla(0, 0%, 0%, 0.993)",
		"secondary-background-glow-high-contrast": "hsla(0, 0%, 37%, 1)",
		"secondary-dark": "hsla(0, 0%, 89%, 0.999)",
		"secondary-hover": "hsla(0, 0%, 78%, 1)",
		"secondary-active": "hsla(0, 0%, 78%, 0.999)",
		"secondary-box-shadow": "hsla(0, 0%, 89%, 0.998)",
		"secondary-invert": "hsla(0, 0%, 0%, 0.992)",
		"tertiary-base": "hsla(0, 0%, 100%, 0.994)",
		"tertiary-background": "hsla(0, 0%, 0%, 0.991)",
		"tertiary-background-glow-high-contrast": "hsla(0, 0%, 100%, 0.993)",
		"tertiary-dark": "hsla(0, 0%, 100%, 0.992)",
		"tertiary-hover": "hsla(0, 0%, 89%, 0.997)",
		"tertiary-active": "hsla(0, 0%, 89%, 0.996)",
		"tertiary-box-shadow": "hsla(0, 0%, 100%, 0.991)",
		"tertiary-invert": "hsla(0, 0%, 0%, 0.99)",
		"success-base": "hsla(120, 77%, 80%, 1)",
		"success-background": "hsla(0, 0%, 0%, 0.989)",
		"success-background-glow-high-contrast": "hsla(120, 77%, 80%, 0.999)",
		"success-dark": "hsla(120, 77%, 90%, 0.999)",
		"success-hover": "hsla(120, 77%, 90%, 0.998)",
		"success-active": "hsla(120, 77%, 90%, 0.997)",
		"success-box-shadow": "hsla(0, 0%, 100%, 0.99)",
		"success-invert": "hsla(0, 0%, 0%, 0.988)",
		"info-base": "hsla(262, 46%, 80%, 1)",
		"info-background": "hsla(0, 0%, 0%, 0.987)",
		"info-background-glow-high-contrast": "hsla(262, 46%, 80%, 0.999)",
		"info-dark": "hsla(262, 46%, 90%, 1)",
		"info-hover": "hsla(262, 46%, 90%, 0.999)",
		"info-active": "hsla(262, 46%, 90%, 0.998)",
		"info-box-shadow": "hsla(0, 0%, 100%, 0.989)",
		"info-invert": "hsla(0, 0%, 0%, 0.986)",
		"warning-base": "hsla(44, 100%, 80%, 0.999)",
		"warning-background": "hsla(0, 0%, 0%, 0.985)",
		"warning-background-glow-high-contrast": "hsla(44, 100%, 80%, 0.998)",
		"warning-dark": "hsla(44, 100%, 90%, 1)",
		"warning-hover": "hsla(262, 46%, 90%, 0.997)",
		"warning-active": "hsla(262, 46%, 90%, 0.996)",
		"warning-box-shadow": "hsla(0, 0%, 100%, 0.988)",
		"warning-invert": "hsla(0, 0%, 0%, 0.984)",
		"danger-base": "hsla(16, 99%, 80%, 1)",
		"danger-background": "hsla(0, 0%, 0%, 0.983)",
		"danger-background-glow-high-contrast": "hsla(16, 99%, 80%, 0.999)",
		"danger-dark": "hsla(16, 99%, 90%, 0.999)",
		"danger-hover": "hsla(16, 99%, 90%, 0.998)",
		"danger-active": "hsla(16, 99%, 90%, 0.997)",
		"danger-box-shadow": "hsla(0, 0%, 100%, 0.987)",
		"danger-invert": "hsla(0, 0%, 0%, 0.982)"
	}
};