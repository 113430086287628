/* eslint-disable */
// reuse TypeScript's assign implementation to polyfill Object.assign.
if (typeof Object.assign !== 'function') {
	Object.defineProperty(Object, 'assign', {
		value:
			Object.assign ||
			function (t: any) {
				for (let s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (const p in s) {
						if (Object.prototype.hasOwnProperty.call(s, p)) {
							t[p] = s[p];
						}
					}
				}
				return t;
			}
	});
}
