import { themeMap, ThemeMap } from '@msdocs/styles';
import { ThemeChangeInfo, ThemeType } from '../theme-selection';

// same regex in build/tasks/stylesheets.js
const hslaRegex = /hsla\(\s*(\d+)\s*,\s*(\d+)%?\s*,\s*(\d+)%?\s*,\s*0?(\.?\d+)\s*\)/;
const globalHslaRegex = new RegExp(hslaRegex.source, 'g');

if (!supportsCSSProperties()) {
	document.documentElement.dataset.cssVariableSupport = 'false'; // places data-css-variable-support="false" on document element for css classes .(not-)css-variable-support

	const stylesheets = Array.from(document.styleSheets)
		.filter(
			s => s instanceof CSSStyleSheet && s.href !== null && s.href.indexOf(location.origin) === 0
		)
		.map(s => s as CSSStyleSheet);

	const inverseThemeMap = createInverseThemesMap(themeMap);

	addEventListener('theme-changed', ({ detail }: CustomEvent<ThemeChangeInfo>) => {
		changeTheme(stylesheets, inverseThemeMap, detail.currentTheme, detail.previousTheme);
	});
}

function supportsCSSProperties() {
	return 'CSS' in window && CSS.supports && CSS.supports('--test', 'red');
}

function changeTheme(
	stylesheets: CSSStyleSheet[],
	inverseThemeMap: ThemeMap,
	appliedTheme: ThemeType,
	previousTheme: ThemeType
) {
	const hslaCorrections: Record<string, string> = {};

	for (const stylesheet of stylesheets) {
		for (let i = 0; i < stylesheet.cssRules.length; i++) {
			const rule = stylesheet.cssRules.item(i) as CSSStyleRule;

			if (rule.type !== CSSRule.STYLE_RULE) {
				continue;
			}

			const cssText = rule.style.cssText.replace(
				globalHslaRegex,
				(_, hue, saturation, lightness, alpha) => {
					const ruleHsla = `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
					let name = inverseThemeMap[previousTheme][ruleHsla] || hslaCorrections[ruleHsla];

					// IE sometimes adjusts the hue or saturation of an hsla color in our CSS rules by
					// plus or minus 1. The following logic searches for our intended hsla color and
					// it's associated semantic name when these adjustments happen.
					// The resulting corrected color is stored in hslaCorrections for quick lookup because
					// multiple CSS rules will likely have been impacted.
					let hueVariance = -1;
					while (!name && hueVariance < 2) {
						let satVariance = -1;
						while (!name && satVariance < 2) {
							const hsla = `hsla(${+hue + hueVariance}, ${
								+saturation + satVariance
							}%, ${lightness}%, ${alpha})`;
							name = inverseThemeMap[previousTheme][hsla];
							if (name) {
								// remember the correction so we don't need to loop next time
								// the invalid hsla is encountered in a rule.
								hslaCorrections[ruleHsla] = name;
								//eslint-disable-next-line
								// console.log(`Color correction: ${rule.cssText}\n${ruleHsla}\n${hsla} (${name}).`);
							}
							satVariance++;
						}
						hueVariance++;
					}

					if (!name) {
						/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
						console.log(
							`Couldn't find ${previousTheme} name for ${ruleHsla}.\n${rule.selectorText}\n${rule.cssText}`
						);
						return ruleHsla;
					}
					return themeMap[appliedTheme][name];
				}
			);

			if (cssText === rule.style.cssText) {
				continue;
			}

			rule.style.cssText = cssText;
		}
	}
}

function createInverseThemesMap(themeMap: ThemeMap) {
	// create lookup with each of the theme properties
	const lookup: ThemeMap = { light: {}, dark: {}, 'high-contrast': {} };
	// iterate through each of them
	for (const theme of [
		'light' as keyof ThemeMap,
		'dark' as keyof ThemeMap,
		'high-contrast' as keyof ThemeMap
	]) {
		for (const name of Object.keys(themeMap[theme])) {
			const hsla = themeMap[theme][name];
			const [, hue, saturation, lightness, alpha] = hsla.match(hslaRegex);
			lookup[theme][`hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`] = name;
		}
	}
	return lookup;
}
