import { window } from '../globals';

if (typeof CustomEvent !== 'function') {
	(window as any).CustomEvent = function CustomEvent(event: string, params: CustomEventInit) {
		params = params || { bubbles: false, cancelable: false, detail: undefined };
		const evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	};

	(window as any).CustomEvent.prototype = Event.prototype;
}
