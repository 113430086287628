/* eslint-disable */
if (!Element.prototype.matches) {
	const prototype = Element.prototype as any;
	Element.prototype.matches = prototype.msMatchesSelector || prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest || document.createElement('a').closest('a') === null) {
	Element.prototype.closest = function (selector: string) {
		let el = this;
		do {
			if (el.matches(selector)) {
				return el;
			}
			el = el.parentElement;
		} while (el !== null);
		return null;
	};
}
