/* eslint-disable */
/* tslint:disable */ // remove after eslint migration

if (!String.prototype.includes) {
	Object.defineProperty(String.prototype, 'includes', {
		value: function (search: string, start: number) {
			if ((search as any) instanceof RegExp) {
				throw new TypeError('first argument must not be a RegExp');
			}
			if (start === undefined) {
				start = 0;
			}
			return this.indexOf(search, start) !== -1;
		}
	});
}
